import { API } from '../api';
import { UtilsString } from '@/utils/utils-string';
import { ssmHttpService } from './http-service';
import { ssmTokenService } from './token-service';
//import { ssmSignalRService } from './signal-r-service';
import { Login } from '../dtos/login';
import { medicalUserService } from './user-service';


export default class AuthorizationService {
    public userName() {
        return medicalUserService.getuser();
    }

    public login(login: Login): Promise<boolean> {
        // return Promise.resolve(this.onLogin({Success: true, Name: login.UserName, Token: 'myTokenHashed'}));

        // Nota: en una aplicación real esta línea debe llamar al API para validar el usuario
        return ssmHttpService.post(API.login+"/paciente", login.toJson())
            .then(this.onLogin.bind(this))
            .catch(this.loginFail.bind(this));
    }

    public logout() {
        medicalUserService.setuser(UtilsString.Empty);
        // Nota: descomentar esta línea si se va a usar SignalR para recibir eventos del servidor
       // ssmSignalRService.leaveChannel();
        ssmTokenService.clearToken();
        ssmHttpService.updateTokenHeader();
    }

    private onLogin(res: any) {
        if (res.Success) {
            medicalUserService.setuser(res.Name);
            ssmTokenService.setToken(res.Token);
            ssmHttpService.updateTokenHeader();

            //ssmSignalRService.setWebChannelHubBase((process.env.VUE_APP_ROOT_CHANNELHUB as string))
            //await ssmSignalRService.setWebChannelHubBase('https://localhost:44352/api/question-hub')
            // Nota: descomentar esta línea si se va a usar SignalR para recibir eventos del servidor
           // ssmSignalRService.joinChannel();

            return true;
        } else {
            return false;
        }
    }

    private loginFail(res: any) {
        return false;
    }
}

export const authorizationService = new AuthorizationService();